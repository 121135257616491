<template>
  <div class="mail">
        <van-nav-bar
            title="邮寄发票"
            left-text="返回"
            left-arrow
            fixed
            @click-left="onClickLeft"
            />
        <div class="tip">
            邮寄发票必须与上传的发票一致，否则不予审核通过
        </div>
        <div class="invoiceWrap" v-if="co_mode==2">
            <div class="invoiceTitle">
                <p class="invoiceType">请选择发票类型</p><p class="invoiceExplain" @click="tokaipiao">发票说明</p>
            </div>
            <div class="invoiceBox">
                <div class="typeInvoice">
                    <ul>
                        <li :class="invoice_type==1?'':'active'" @click="modifyType(1)">
                            <img :src="invoice_type==1?invoiceImg:invoiceImgDefault" alt="">
                            <span :class="invoice_type==1?'':'active'">电子发票</span>
                        </li>
                        <li :class="invoice_type==2?'':'active'" @click="modifyType(2)">
                            <img :src="invoice_type==2?invoiceImg:invoiceImgDefault" alt="">
                            <span :class="invoice_type==2?'':'active'">纸质发票</span>
                        </li>
                    </ul>
                </div>
                <div class="uploadImg clearfix" v-if="invoice_type==1">
                    <div class="uploadBox fl">
                        <van-uploader v-model="fileList0" :upload-icon="uploadInvoiceImg" accept="image/*" @delete="delete1" :after-read="invoice1" :max-count="1" multiple  :class="fileList0[0]?'':'imgHide'"/>
                        <img :src="uploadInvoiceImg" alt="" v-if="!fileList0[0]">
                    </div>
                    <div class="pdfBox fr">
                        <van-uploader v-model="fileList1" :upload-icon="uploadInvoiceImg"  @delete="delete2" accept="application/pdf" :after-read="invoice2" :max-count="1" multiple :class="fileList1[0]?'':'imgHide'"/>
                        <img :src="uploadPdf" alt="" v-if="!fileList1[0]">
                        <p>点击上传pdf格式的发票</p>
                    </div>
                </div>
                <div class="uploadImg " v-if="invoice_type==2">
                    <div class="uploadBox ">
                        <van-uploader v-model="fileList2" :upload-icon="uploadInvoiceImg" accept="image/*" @delete="delete1"  :after-read="invoice3" :max-count="1" multiple :class="fileList2[0]?'':'imgHide'"/>
                        <img :src="uploadInvoiceImg" alt="" v-if="!fileList2[0]">
                    </div>
                </div>
            </div>
        </div>
        <div class="content" v-if="invoice_type==2">
            <div class="title">
                发票寄出信息
            </div>
            <div class="wrap">
                <div class="inputbox">
                    <p class="name">快递公司名称</p>
                    <van-field v-model="dataK.name"  placeholder="请输入快递公司全称" />
                </div>
                <div class="inputbox">
                    <p class="name">快递运单号</p>
                    <van-field v-model="dataK.num"  placeholder="请输入快递公司全称" />
                </div>
            </div>
           
        </div>
            <div class="btn" @click="applyMail">
                确认提交
            </div>
  </div>
</template>

<script>
import api from "@/api/api"
export default {
    data(){
        return{
            id:"",
            openid:"",login:localStorage.getItem("login_token"),
            // openid:"oftPL6mLE4pyfl5-SijSk9JM_KPM",login:"gKm5AUr3FaMG74EUSYbE9kvb6K72c5yNdVtRxvSjuXdHeXrGFQ",
            dataK:{
                name:"",
                num:"",
            },
            invoiceImg:require("@/img/apply/icon_xuanzhong.png"),invoiceImgDefault:require("@/img/apply/icon_weixuanzhong.png"),
            uploadInvoiceImg:require("@/img/apply/shangchuanzhizhifapiao.png"),uploadPdf:require("@/img/apply/shangchuanpdf.png"),
            co_mode:2,
            fileList0: [],fileList1: [],fileList2: [],invoice_type:1,invoice_img:"",invoice_file:"",
        }
    },
    methods:{
        tokaipiao(){
            this.$router.push({
                path:"/realname/invoice/explain"
            })
        },
        delete1(file){
            this.invoice_img = ""
            console.log(file)
        },
        delete2(file){
            this.invoice_file = ""
            console.log(file)
        },
        modifyType(i){
            this.invoice_type=i
            // this.invoice_img = ""
            // this.invoice_file = ""
        },
        onClickLeft() {
            this.$router.go(-1)
        },
        applyMail(){
            var that = this
            if(this.invoice_img == ""){
                    this.$toast("发票图片必传")
                    return
                }
            if(this.invoice_type == 1){
                if(this.invoice_file == ""){
                    this.$toast("pdf格式发票必传")
                    return
                } 
            }else{
                 if(this.dataK.name == ""){
                    this.$toast("快递公司必填")
                    return
                }
                if(this.dataK.num == ""){
                    this.$toast("快递运单号必填")
                    return
                }
            }
            that.$toast.loading({
                message: '加载中...',
                forbidClick: true,
                duration: 0
			});
            api.apply.applyMail(this.openid,this.login,this.id,this.dataK.name,this.dataK.num,this.invoice_type,this.invoice_img,this.invoice_file).then(res =>{
                 that.$toast.clear();
                if(res.data.code == 0){
                    this.$toast(res.data.msg)
                    this.$router.go(-1)
                }else{
                    this.$toast(res.data.msg)
                }
            }).catch(req =>{
                console.log(req)
                that.$toast.clear();
            })
        },
                // 上传发票电子
        invoice1(file){
            let data=new FormData();
            file.status = 'uploading';
            file.message = '上传中...';
            data.append('file',file.file);
            api.my.uploadIMg(data).then(res =>{
                console.log(res)
                if(res.data.uploaded){
                    this.invoice_img=res.data.url
                    file.status = '';
                    file.message = '';
                }else{
                    file.status = 'failed';
                    file.message = '上传失败';
                }
            }).catch(req =>{
                console.log(req)
                this.$toast(req.data.msg)
            })
        },
        // 上传pdf格式
        invoice2(file){
            if(file.file.type != "application/pdf"){
                this.$toast("请上传pdf格式文件")
                this.invoice_file = ""
                this.fileList1 = []
                return false
            }else{
                let data=new FormData();
                file.status = 'uploading';
                file.message = '上传中...';
                data.append('file',file.file);
                api.my.uploadIMg(data).then(res =>{
                    if(res.data.uploaded){
                        this.invoice_file=res.data.url
                        file.status = '';
                        file.message = '';
                    }else{
                        file.status = 'failed';
                        file.message = '上传失败';
                    }
                }).catch(req =>{

                    this.$toast(req.data.msg)
                })
                
            }

        },
        // 上传纸质发票
        invoice3(file){
            let data=new FormData();
            file.status = 'uploading';
            file.message = '上传中...';
            data.append('file',file.file);
            api.my.uploadIMg(data).then(res =>{
                console.log(res)
                if(res.data.uploaded){
                   this.invoice_img=res.data.url
                    file.status = '';
                    file.message = '';
                }else{
                    file.status = 'failed';
                    file.message = '上传失败';
                }
            }).catch(req =>{
                this.$toast(req.data.msg)
            })
        },
        getdata(){
            var that = this
            that.$toast.loading({
                message: '加载中...',
                forbidClick: true,
                duration: 0
			});
            api.apply.getMail(this.openid,this.login,this.id).then(res =>{
                 that.$toast.clear();
                 console.log(res)
                if(res.data.code == 0){
                    var data=res.data.data
                    if(data.invoice_type){
                       
                        this.invoice_type=res.data.data.invoice_type
                        if(data.invoice_type == 1){
                             this.invoice_img=res.data.data.invoice_img
                            this.$set(this.fileList0,0,{url:res.data.data.invoice_img})
                            this.fileList1[0] = {url:res.data.data.invoice_file}
                            this.invoice_file=res.data.data.invoice_file 
                        }else{
                             this.invoice_img=res.data.data.invoice_img
                            this.$set(this.fileList2,0,{url:res.data.data.invoice_img})
                            this.$set(this.dataK,'name',res.data.data.express_company)
                            this.$set(this.dataK,'num',res.data.data.express_no)
                        }
                    }
                    this.name = res.data.express_company
                    this.num = res.data.express_no
                }else{
                    this.$toast(res.data.msg)
                }
            }).catch(req =>{
                console.log(req)
                that.$toast.clear();
            })
        }
    },
    created(){
        this.id = this.$route.query.id
        this.openid = this.$store.state.openid
        this.getdata()
    }
}
</script>

<style lang="less">
.mail{
    width: 100%;
    height: 100%;
    background: #f6f6f6;
     padding-top: 1rem;
    .tip{
        width: 100%;
        background-color:rgba(255,247,247,1);
        padding:.4rem;
        box-sizing: border-box;
        font-size: .28rem;
        color:rgba(215,182,185,1);
    }
    .invoiceWrap{
        width: 100%;
        padding:0 .24rem;
        box-sizing: border-box;
        background-color:#f6f6f6;
        .invoiceTitle{
            display: flex;
            justify-content: space-between;
            line-height:.6rem;
            font-size:.28rem;
            color:#767575;
        }
        .invoiceBox{
            width: 100%;
            padding:.4rem .6rem;
            box-sizing: border-box;
            background-color:#fff;
            border-radius:.2rem;
            .typeInvoice{
                ul{
                    display: flex;
                    justify-content: space-between;
                    li{
                        width: 2rem;
                        height: .72rem;
                        border:.02rem solid #f15223;
                        line-height: .72rem;
                        box-sizing: border-box;
                        text-align: center;
                        border-radius:.1rem;
                        img{
                            width: .36rem;
                            height: .36rem;
                            margin-right: .1rem;
                            vertical-align: middle;
                        }
                        span{
                            font-size:.3rem;
                            color:#F15223;
                            vertical-align: middle;
                        }
                        .active{
                            color:#D6D6D6;
                        }
                    }
                    .active{
                        border-color:#D6D6D6;
                    }
                }
            }
            .uploadImg{
                width: 100%;
                padding:.6rem 0 ;
                height: 4.1rem;
                box-sizing: border-box;
                .uploadBox{
                    width: 2.5rem;
                    position: relative;
                    margin: 0 auto;
                    img{
                        width: 100%;
                    }
                    .van-uploader{
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 100%;
                        .van-uploader__upload{
                            width: 100%;
                            .van-uploader__preview{
                                width: 100%;
                                .van-uploader__preview-image{
                                    width: 100%;
                                }
                            }
                        }
                    }
                    .van-uploader__preview-image{
                        width: 2.5rem;
                    }
                    .imgHide{
                        opacity: 0;
                    }
                }
                .pdfBox{
                    width: 2.3rem;
                    height:2.66rem;
                    position: relative;
                    img{
                        width: 100%;
                        height: 100%;
                    }
                    p{
                        position: absolute;
                        bottom: -.8rem;
                        font-size:.22rem;
                        color:#FF6767;
                    }
                    .van-uploader{
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 100%;
                        height: 100%;
                        .van-uploader__upload{
                            width: 100%;
                            height: 100%;
                            
                        }
                        .van-uploader__file{
                            width: 2.3rem;
                            height:2.66rem;
                        }
                    }
                    .imgHide{
                        opacity: 0;
                        
                    }
                }
            }
        }
    }
    .content{
        width: 100%;
        .title{
            padding: .32rem .28rem;
            font-size:.28rem;
        }
        .wrap{
            width: 100%;
            background:#fff;
            padding: 0 .4rem .3rem ;
            box-sizing: border-box;
            margin-bottom: 1rem;
            .inputbox{
                border-bottom:.02rem solid rgba(229,227,227,0.6);
                padding-top:.3rem;
                margin-bottom: .3rem;
                .name{
                    padding-left: .3rem;
                   
                    font-size:.3rem;
                    color:rgba(65,65,65,1);
                }
            }
          
        }

    }
    .btn{
        width: 5.86rem;
        height: .88rem;
        margin: .7rem auto;
        background: rgba(241,82,35,1);
        line-height: .88rem;
        text-align: center;
        color: #fff;
        border-radius: 2rem;
    }
}
</style>